@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (overflow-wrap: anywhere) {
  .break-anywhere {
    overflow-wrap: anywhere;
  }
}
@supports not (overflow-wrap: anywhere) {
  .break-anywhere {
    word-break: break-word;
  }
}

@layer base { 
  a {
    -webkit-user-drag: none;
  }
  
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
}

@media print {
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  
  @page {
    size: A4;
    margin: 0;
  }
}


@layer utilities {
  .no-scrollbar {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  a {
    -webkit-user-drag: none;
  }
}

@layer components {
  .text-loading {
    position: relative;
    overflow: hidden;
    @apply bg-soft-pressed *:invisible *:opacity-0;
    border-radius: 0.75rem;

    color: transparent !important;
    width: fit-content;
  }

  .bg-dashed{
    background-image: url('data:image/svg+xml,<svg width="100%" height="2" viewBox="0 0 308 2" fill="none" xmlns="http://www.w3.org/2000/svg" ><path fill-rule="evenodd" clip-rule="evenodd" d="M8.6122 1.5H4.56733V0.5H8.6122V1.5ZM16.7019 1.5H12.6571V0.5H16.7019V1.5ZM24.7917 1.5H20.7468V0.5H24.7917V1.5ZM32.8814 1.5H28.8366V0.5H32.8814V1.5ZM40.9712 1.5H36.9263V0.5H40.9712V1.5ZM49.0609 1.5H45.0161V0.5H49.0609V1.5ZM57.1507 1.5H53.1058V0.5H57.1507V1.5ZM65.2404 1.5H61.1955V0.5H65.2404V1.5ZM73.3301 1.5H69.2853V0.5H73.3301V1.5ZM81.4199 1.5H77.375V0.5H81.4199V1.5ZM89.5096 1.5H85.4648V0.5H89.5096V1.5ZM97.5994 1.5H93.5545V0.5H97.5994V1.5ZM105.689 1.5H101.644V0.5H105.689V1.5ZM113.779 1.5H109.734V0.5H113.779V1.5ZM121.869 1.5H117.824V0.5H121.869V1.5ZM129.958 1.5H125.913V0.5H129.958V1.5ZM138.048 1.5H134.003V0.5H138.048V1.5ZM146.138 1.5H142.093V0.5H146.138V1.5ZM154.228 1.5H150.183V0.5H154.228V1.5ZM162.317 1.5H158.272V0.5H162.317V1.5ZM170.407 1.5H166.362V0.5H170.407V1.5ZM178.497 1.5H174.452V0.5H178.497V1.5ZM186.587 1.5H182.542V0.5H186.587V1.5ZM194.676 1.5H190.631V0.5H194.676V1.5ZM202.766 1.5H198.721V0.5H202.766V1.5ZM210.856 1.5H206.811V0.5H210.856V1.5ZM218.946 1.5H214.901V0.5H218.946V1.5ZM227.035 1.5H222.99V0.5H227.035V1.5ZM235.125 1.5H231.08V0.5H235.125V1.5ZM243.215 1.5H239.17V0.5H243.215V1.5ZM251.305 1.5H247.26V0.5H251.305V1.5ZM259.394 1.5H255.349V0.5H259.394V1.5ZM267.484 1.5H263.439V0.5H267.484V1.5ZM275.574 1.5H271.529V0.5H275.574V1.5ZM283.663 1.5H279.619V0.5H283.663V1.5ZM291.753 1.5H287.708V0.5H291.753V1.5ZM299.843 1.5H295.798V0.5H299.843V1.5ZM307.933 1.5H303.888V0.5H307.933V1.5Z" fill="%23D2D6D4" /></svg>');
    background-repeat: repeat-x;
    height: 2px;
  }
}
